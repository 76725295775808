import { render, staticRenderFns } from "./InactiveCategory.vue?vue&type=template&id=766e6a67&scoped=true&"
import script from "./InactiveCategory.vue?vue&type=script&lang=js&"
export * from "./InactiveCategory.vue?vue&type=script&lang=js&"
import style0 from "./InactiveCategory.vue?vue&type=style&index=0&id=766e6a67&prod&lang=scss&scoped=true&"
import style1 from "./InactiveCategory.vue?vue&type=style&index=1&id=766e6a67&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "766e6a67",
  null
  
)

export default component.exports