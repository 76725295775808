<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            v-if="isMobile()"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <div class="bulk_selection d-flex">
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectInactiveAnnouncementCategoryCheckbox"
                  :indeterminate="isSelectInactiveAnnouncementCatCheckboxIndeterminate"
                  @change="selectAllCheckboxUpdate"
                >
                  Select All
                </b-form-checkbox>
              </div>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1"
                right
                text="Bulk Actions"
                variant="primary"
                :disabled="!selectedAnnouncementCategories.length"
              >
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'AgentAnnouncementCategory')"
                  v-b-modal.modal-update-status-all-announcement-category-bulk
                >
                  Update Status
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <b-col
            v-else
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectInactiveAnnouncementCategoryCheckbox"
                :indeterminate="isSelectInactiveAnnouncementCatCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedAnnouncementCategories.length"
            >
              <b-dropdown-item
                v-if="canViewThisAction('update', 'AgentAnnouncementCategory')"
                v-b-modal.modal-update-status-all-announcement-category-bulk
              >
                Update Status
              </b-dropdown-item>
            </b-dropdown>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-all-announcement-category"
                ref="filter_dropdown_all_announcement_category"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <category-dropdown-filters
                  :status-filter.sync="statusFilter"
                  :start-date.sync="startDateFilter"
                  :status-options="statusOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="startDateFilter || statusFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-if="startDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="startDateFilter = ''"
                  >
                    {{ startDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in statusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refInactiveAnnouncementCategoriesTable"
        class="position-relative event_list min-height-300"
        :items="fetchAnnouncementCategories"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(name)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedAnnouncementCategories.includes(data.item._id)"
              @change="toggleSelectedAnnouncementCategories(data.item._id)"
              @click.native.stop
            >
              <span
                class="text-bold-black align-middle detail-view-id"
              >
                {{ data.item.name }}
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(createdBy)="data">
          <div class="text-nowrap">
            <span>{{ data.item.createdBy.name }}</span>
            <br>
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveAnnouncementCategoryStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('create', 'AgentAnnouncementCategory') || canViewThisAction('show', 'AgentAnnouncementCategory') || canViewThisAction('update', 'AgentAnnouncementCategory') || canViewThisAction('delete', 'AgentAnnouncementCategory')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'AgentAnnouncementCategory')"
              :to="{ name: 'agencies-announcement-categories-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'AgentAnnouncementCategory')"
              :to="{ name: 'agencies-announcement-categories-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'AgentAnnouncementCategory')"
              id="toggle-btn"
              v-b-modal.modal-update-status-inactive-announcement-category
              @click="setAnnouncementCategory(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Status</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'AgentAnnouncementCategory')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalAnnouncementCategories > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalAnnouncementCategories"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-update-status-inactive-announcement-category"
      ref="update-status-modal"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="announcementCategoryStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-announcement-category-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-announcement-category-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-update-status-all-announcement-category-bulk"
      ref="update-status-modal-published-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="announcementCategoryBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-announcement-category-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-announcement-category-status"
                v-model="bulkStatus"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkStatusValidation == true ? bulkStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BForm, BFormGroup, BFormTag,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useInactiveAnnouncementCategoryList from './useInactiveCategoryList'
import announcementCategoryStoreModule from '../announcementCategoryStoreModule'
import CategoryDropdownFilters from '../CategoryDropdownFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    CategoryDropdownFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      announcementCategoryID: '',
      name: '',
      status: 'active',
      bulkStatus: 'active',
      statusValidation: false,
      statusError: 'Valid status is required',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_all_announcement_category.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_announcement_category.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.startDateFilter = ''
      this.statusFilter = []
    },
    setAnnouncementCategory(item) {
      this.announcementCategoryID = item._id
      this.status = item.status
      this.name = item.name
    },
    updateBulkStatus() {
      this.$refs.announcementCategoryBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedAnnouncementCategories.length; i++) {
            formData.append('announcementCategories[]', this.selectedAnnouncementCategories[i])
          }

          this.$http.patch('customer/announcement-categories/action/bulk/status/update', formData)
            .then(() => {
              this.refetchData()
              this.$emit('emitCountAnnouncementCategory')
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected announcement categories has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    submitForm() {
      this.$refs.announcementCategoryStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`customer/announcement-categories/${this.announcementCategoryID}/status/update`, formData)
            .then(() => {
              this.refetchData()
              this.$emit('emitCountAnnouncementCategory')
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>‘${this.name}’</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Delete this Announcement Category?',
        html: 'Are you sure you want to delete this announcement category? Deleting the category will also delete all associated questions. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete-yellow.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, delete',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.toDeletedID = id
            await this.deleteAnnouncementCategory()
            this.$emit('emitCountAnnouncementCategory')
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const ANN_CATEGORY_APP_STORE_MODULE_NAME = 'agencies-inactive-announcement-categories'

    // Register module
    if (!store.hasModule(ANN_CATEGORY_APP_STORE_MODULE_NAME)) store.registerModule(ANN_CATEGORY_APP_STORE_MODULE_NAME, announcementCategoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ANN_CATEGORY_APP_STORE_MODULE_NAME)) store.unregisterModule(ANN_CATEGORY_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchAnnouncementCategories,
      tableColumns,
      perPage,
      currentPage,
      totalAnnouncementCategories,
      announcementCategoryData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInactiveAnnouncementCategoriesTable,
      refetchData,

      toDeletedID,
      deleteAnnouncementCategory,

      // UI
      resolveAnnouncementCategoryStatusVariant,

      // Extra Filters
      statusFilter,
      startDateFilter,
    } = useInactiveAnnouncementCategoryList()

    const selectedAnnouncementCategories = ref([])
    const toggleSelectedAnnouncementCategories = announcementCategoryID => {
      const announcementCategoryIndex = selectedAnnouncementCategories.value.indexOf(announcementCategoryID)

      if (announcementCategoryIndex === -1) selectedAnnouncementCategories.value.push(announcementCategoryID)
      else selectedAnnouncementCategories.value.splice(announcementCategoryIndex, 1)
    }
    const selectInactiveAnnouncementCategoryCheckbox = computed(() => announcementCategoryData.value.length && (announcementCategoryData.value.length === selectedAnnouncementCategories.value.length))
    const isSelectInactiveAnnouncementCatCheckboxIndeterminate = computed(() => Boolean(selectedAnnouncementCategories.value.length) && announcementCategoryData.value.length !== selectedAnnouncementCategories.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedAnnouncementCategories.value = val ? announcementCategoryData.value.map(book => book._id) : []
    }

    return {

      // Sidebar

      fetchAnnouncementCategories,
      tableColumns,
      perPage,
      currentPage,
      totalAnnouncementCategories,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInactiveAnnouncementCategoriesTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteAnnouncementCategory,
      announcementCategoryData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveAnnouncementCategoryStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
      startDateFilter,

      selectInactiveAnnouncementCategoryCheckbox,
      isSelectInactiveAnnouncementCatCheckboxIndeterminate,
      selectedAnnouncementCategories,
      toggleSelectedAnnouncementCategories,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
