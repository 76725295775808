<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Announcement Categories
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For Nirvana Agent SG</strong> This is the control panel where administrators can edit the content for the agent-facing mobile app.</span>
      </div>
    </b-alert>

    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            variant="success"
            :to="{ name: 'agencies-announcement-categories-create' }"
            class="mobile_create_button"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create new Announcement Category</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Account -->
          <b-tab
            :active="$route.params.type == 'active'"
            lazy
            @click="changeRoute('active')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Active <span class="rounded-number">{{ activeCount }}</span></span>
            </template>
            <active-category
              class="mt-2 pt-75"
              @emitCountAnnouncementCategory="countAnnouncementCategories"
            />
          </b-tab>

          <!-- Tab: Information -->
          <b-tab
            :active="$route.params.type == 'inactive'"
            lazy
            @click="changeRoute('inactive')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Inactive <span class="rounded-number">{{ inactiveCount }}</span></span>
            </template>
            <inactive-category
              class="mt-2 pt-75"
              @emitCountAnnouncementCategory="countAnnouncementCategories"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'all-announcement-category'"
            lazy
            @click="changeRoute('all-announcement-category')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Announcement Category <span class="rounded-number">{{ allCount }}</span></span>
            </template>
            <all-category
              class="mt-2 pt-75"
              @emitCountAnnouncementCategory="countAnnouncementCategories"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllCategory from './all-categories/AllCategory.vue'
import ActiveCategory from './active-categories/ActiveCategory.vue'
import InactiveCategory from './inactive-categories/InactiveCategory.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,
    BAlert,

    AllCategory,
    ActiveCategory,
    InactiveCategory,
  },
  data() {
    return {
      allCount: 0,
      activeCount: 0,
      inactiveCount: 0,
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Active',
          param: 'active',
          count: 0,
        },
        {
          title: 'Inactive',
          param: 'inactive',
          count: 0,
        },
        {
          title: 'All Announcement Category',
          param: 'all-announcement-category',
          count: 0,
        },
      ],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countAnnouncementCategories()
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    countAnnouncementCategories() {
      this.$http.get('customer/announcement-categories/counts')
        .then(response => {
          this.allCount = response.data.allCount
          this.activeCount = response.data.activeCount
          this.inactiveCount = response.data.inactiveCount

          this.pageTabs.forEach(element => {
            if (element.title === 'All Announcement Category') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allCount
            } else if (element.title === 'Inactive') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.inactiveCount
            } else if (element.title === 'Active') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.activeCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
